import {
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  ref,
} from "vue";

export default () => {
  const isActivated = ref(false);
  onActivated(() => {
    isActivated.value = true;
  });
  onDeactivated(() => {
    isActivated.value = false;
  });

  onMounted(() => {
    isActivated.value = true;
  });
  onBeforeUnmount(() => {
    isActivated.value = false;
  });

  return {
    isActivated,
  };
};
