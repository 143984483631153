import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57d6d875"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-services" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "the-services-item-title" }
const _hoisted_5 = { class: "the-services-item-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pageServices, (service) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "the-services-item",
          key: service.title
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["the-services-item-icon", service.iconClass])
          }, null, 2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(service.title), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(service.description), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}