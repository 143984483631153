const vClickOutside = {
  beforeMount(el: any, binding: any): void {
    let onDownIsOutside = false;
    el.onMouseDown = function (event: MouseEvent) {
      onDownIsOutside = !el.contains(event.target);
    };
    el.onMouseUp = function (event: MouseEvent) {
      if (onDownIsOutside && !el.contains(event.target)) {
        binding.value(event);
      }
    };
    setTimeout(() => {
      document.body.addEventListener("mousedown", el.onMouseDown);
      document.body.addEventListener("mouseup", el.onMouseUp);
    });
  },
  unmounted(el: any): void {
    document.body.removeEventListener("mousedown", el.onMouseDown);
    document.body.removeEventListener("mouseup", el.onMouseUp);
  },
};

export default vClickOutside;
