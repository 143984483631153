import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60b3185a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "the-footer",
  id: "the-footer"
}
const _hoisted_2 = { class: "the-footer-main flex-wrapper" }
const _hoisted_3 = { class: "flex-wrapper logo-wrapper" }
const _hoisted_4 = { class: "flex-wrapper links" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "logo",
          to: '/'
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["NButton"], {
          bordered: false,
          onClick: $setup.showPrivacy
        }, {
          default: _withCtx(() => [
            _createTextVNode("Privacy Settings")
          ]),
          _: 1
        }),
        _createElementVNode("a", { href: $setup.privacyPolicy }, "Privacy Policy", 8, _hoisted_5),
        _createElementVNode("a", { href: $setup.termsOfUse }, "Terms of Use", 8, _hoisted_6),
        _createVNode(_component_router_link, {
          class: _normalizeClass({hidden: $setup.hideSupport}),
          to: "#"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Support")
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}